<template>
  <div
    class="thumbnail"
    :class="{ 'readonly': readonly }"
    :data-id="id"
    :data-selected="selected"
    @click="handleClick"
    @mouseover="handleMouseover"
    @mouseleave="handleMouseleave"
  >
    <img class="thumb" :src="cImage" alt="product" />
    <span class="price">${{ formatPrice(price) }}</span>
    <div class="info-wrapper">
      <div class="info">
        <p>{{ name }}</p>
        <div class="info-button" @click="handleInfo">
          <img src="@/assets/images/info.svg" />
        </div>
        <img
          v-if="isMobile && !readonly"
          class="deselect-button"
          src="@/assets/images/close.svg"
          alt="close"
          @click="handleDeselect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import gsap from 'gsap'
import { cloudinaryImage } from '@/assets/scripts/helpers'

export default {
  name: 'room-builder-thumbnail',

  props: {
    id: {
      type: String,
      default: ''
    },
    src: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isMobile: {
      deep: true,
      handler: function (val) {
        if (this.readonly) return

        if (val) {
          gsap.set(this.$el, {
            height: 'auto'
          })
          if (this.selected) {
            this.select()
          } else {
            this.deselect()
          }
          this.$el.querySelector('.thumb').style.opacity = 1.0
        } else {
          gsap.set(this.$el, {
            height: this.startingHeight
          })
          this.deselect()

          if (this.selected) {
            this.$el.querySelector('.thumb').style.opacity = 0.2
          }
        }
      }
    }
  },

  data () {
    return {
      selected: false,
      startingHeight: null
    }
  },

  computed: {
    cImage () {
      return cloudinaryImage(this.src, 500)
    }
  },

  async mounted () {
    await nextTick()

    if (!this.readonly) {
      this.emitter.on('SELECTED_ITEMS', (items) => {
        var result = items.find(item => item.id === this.id)
        if (result !== undefined) {
          this.selected = true
          this.$el.dataset.select = true
          this.select()
        } else {
          this.selected = false
          this.$el.dataset.select = false
          this.deselect()
        }
      })
    }

    const furnitureImage = new Image()
    furnitureImage.onload = () => {
      this.startingHeight = this.$el.clientHeight - this.$el.querySelector('.info').clientHeight

      if (window.innerWidth <= 1024 || this.readonly) {
        gsap.set(this.$el, {
          height: 'auto'
        })
      } else {
        gsap.set(this.$el, {
          height: this.startingHeight
        })
      }
    }

    furnitureImage.src = this.$el.firstChild.src
  },

  methods: {
    handleClick (e) {
      e = e || window.event
      e.preventDefault()

      if (this.readonly) {
        this.$emit('click', this.id)
        return
      }

      if (!this.selected) {
        this.emitter.emit('THUMBNAIL_SELECT', {
          id: this.id,
          target: this.$el
        })
      }
    },

    handleDeselect (e) {
      if (this.readonly) return

      e = e || window.event
      e.preventDefault()
      e.stopImmediatePropagation()

      if (this.selected) {
        this.emitter.emit('THUMBNAIL_DESELECT', {
          id: this.id
        })
      }
    },

    handleMouseover () {
      if (this.isMobile || this.readonly) return
      gsap.to(this.$el, {
        height: this.startingHeight + this.$el.querySelector('.info').clientHeight,
        duration: 0.3,
        ease: 'Cubic.easeInOut'
      })
    },

    handleMouseleave () {
      if (this.isMobile || this.readonly) return
      gsap.to(this.$el, {
        height: this.startingHeight,
        duration: 0.3,
        ease: 'Cubic.easeInOut'
      })
    },

    handleInfo (e) {
      e = e || window.event
      e.preventDefault()
      e.stopImmediatePropagation()

      if (this.readonly) {
        this.$emit('click', this.id)
      } else {
        this.emitter.emit('ITEM_INFO', this.id)
      }
    },

    deselect () {
      if (this.readonly) return
      gsap.to(this.$el, {
        border: '0px solid #A8B2AA',
        duration: 0.3,
        ease: 'Cubic.easeInOut'
      })
    },

    select () {
      if (this.readonly) return
      if (this.isMobile) {
        gsap.to(this.$el, {
          border: '6px solid #A8B2AA',
          duration: 0.3,
          ease: 'Cubic.easeInOut'
        })
      }
    },
    formatPrice (price) {
      if (price === undefined || price === null) return ''
      const numericPrice = typeof price === 'string' ? parseFloat(price) : price
      return numericPrice.toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 200px;
  background: white;
  filter: drop-shadow(0 0.1rem 0.15rem rgba(0, 0, 0, 0.2));
  border-radius: 13px;
  margin-bottom: 1rem;
  overflow: hidden;

  &.readonly {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

      .info {
        background: rgba(#F9F8F4, 0.9);
      }
    }
  }

  .thumb {
    width: 100%;
    margin-bottom: 5px;
  }

  .info-wrapper {
    position: relative;
    margin-top: 10px;
    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #73716F;
      padding-bottom: 20px;
      padding-left: 5px;
      padding-right: 5px;
      transition: background 0.2s ease;

      p {
        margin-bottom: 8px;
      }

      .info-button {
        width: 17px;
        height: 17px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 5px;
      }

      .deselect-button {
        width: 17px;
        height: 17px;
        cursor: pointer;
      }
    }
  }
}

.price{
  margin: 20px 0 !important;
  font-weight: 500;
}

@include breakpoint($m-down) {
  .thumbnail {
    width: 100%;
  }
}
</style>
