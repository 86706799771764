<template>
  <div class='room-builder-thumbnails' :class="{ 'readonly': readonly }">
    <div class="thumbnails" v-for="(product, index) in products" :key="index">
      <RoomBuilderThumbnail
        class="thumbnail"
        :id="product.recordId"
        :src="product.cloudinaryImages && product.cloudinaryImages[0] ? product.cloudinaryImages[0].url : ''"
        :name="product.name"
        :price="product.itemPrice"
        :isMobile="isMobile"
        :readonly="readonly"
        @click="handleThumbnailClick(product.recordId)"
      />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import RoomBuilderThumbnail from '@/components/room-builder/room-builder-thumbnail.vue'

export default {
  name: 'room-builder-thumbnails',

  components: {
    RoomBuilderThumbnail
  },

  props: {
    products: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    gsap.delayedCall(1, () => {
    })
  },

  methods: {
    handleThumbnailClick (productId) {
      if (this.readonly) {
        this.$emit('click-thumbnail', productId)
      } else {
        this.emitter.emit('THUMBNAIL_SELECT', { id: productId })
      }
    },

    select (e) {
      gsap.to(e, {
        border: '5px solid #ff0000',
        duration: 0.3,
        ease: 'Cubic.easeInOut'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.room-builder-thumbnails {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 710px;
  overflow-y: scroll;
  align-items: center;

  &.readonly {
    .thumbnail {
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

.thumbnails:last-child {
  margin-bottom: 54px;
}

.room-builder-thumbnails::-webkit-scrollbar {
  width: 0 !important
}

.room-builder-thumbnails {
  overflow: -moz-scrollbars-none;
}

.room-builder-thumbnails {
  -ms-overflow-style: none;
}

@include breakpoint($m-down) {
  .room-builder-thumbnails {
    max-height: calc(100vh - 380px);
    min-height: 400px;
  }
}
</style>
